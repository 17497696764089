<template>
  <!-- footer area start -->
  <footer
    class="footer-area mg-top-120"
    style="background-image: url(assets/img/bg/footer.png)"
  >
    <div class="footer-top pd-top-120 padding-bottom-40">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col">
            <div class="footer-widget widget text-center">
              <div class="footer_top_widget">
                <router-link class="footer-logo" :to="{ name: 'home' }"
                  ><img
                    :src="require('@/assets/img/logo.png')"
                    alt="footer logo"
                /></router-link>
              </div>
            </div>
            <div class="footer-widget widget widget_nav_menu text-center">
              <ul>
                <li>
                  <router-link class="nav-link" :to="{ name: 'home' }"
                    >Home</router-link
                  >
                </li>
                <li>
                  <router-link class="nav-link" :to="{ name: 'our-concept' }"
                    >Our Concept</router-link
                  >
                </li>
                <li>
                  <router-link class="nav-link" :to="{ name: 'data-mining' }"
                    >Data mining</router-link
                  >
                </li>
                <li>
                  <router-link
                    class="nav-link"
                    :to="{ name: 'data-visualisation' }"
                    >Data visualisation</router-link
                  >
                </li>
                <li>
                  <router-link
                    class="nav-link"
                    :to="{ name: 'market-analysis' }"
                    >Market analysis</router-link
                  >
                </li>
                <li>
                  <router-link class="nav-link" :to="{ name: 'it-services' }"
                    >IT service</router-link
                  >
                </li>
                <li>
                  <router-link class="nav-link" :to="{ name: 'articles' }"
                    >Articles</router-link
                  >
                </li>
              </ul>
            </div>
            <div class="copyright-inner">
              <div class="row custom-gutters-16">
                <div class="col">
                  <div class="copyright-text">
                    &copy; FarePlay 2023, all rights reserved.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- footer area end -->
</template>