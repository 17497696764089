import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getAnalytics, logEvent } from "firebase/analytics"

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyChKaHZnz0bLnKEMRyf0z5oRU66ThTNiwM",
  authDomain: "lucid-sol-219813.firebaseapp.com",
  projectId: "lucid-sol-219813",
  storageBucket: "lucid-sol-219813.appspot.com",
  messagingSenderId: "61477042971",
  appId: "1:61477042971:web:e8939522b5b79a467731be",
  measurementId: "G-YTEZ07ZDEC",
}

// TODO: cookie consent screen
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)
const analytics = getAnalytics(firebaseApp)
logEvent(analytics, "test_event")

let app = createApp(App)

app.use(store).use(router)

store.dispatch("articles/getArticles")

router.isReady().then(() => {
  app.mount("#app")
})
