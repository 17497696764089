<template>
  <!-- navbar area start -->
  <nav class="navbar navbar-area navbar-expand-lg nav-style-01">
    <div class="container nav-container">
      <div class="responsive-mobile-menu">
        <div class="logo-wrapper mobile-logo">
          <div class="d-flex">
            <router-link class="logo" :to="{ name: 'home' }"
              ><img
                :src="require('@/assets/img/logo.png')"
                class="imgLogo"
                alt="logo"
            /></router-link>
            <h4 class="textLogo d-inline-block my-auto ml-2">fareplay.aero</h4>
          </div>
        </div>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#main_menu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggle-icon">
            <span class="line"></span>
            <span class="line"></span>
            <span class="line"></span>
          </span>
        </button>
      </div>
      <div class="collapse navbar-collapse" id="main_menu">
        <div class="logo-wrapper desktop-logo">
          <div class="d-flex">
            <router-link class="logo" :to="{ name: 'home' }"
              ><img
                :src="require('@/assets/img/logo.png')"
                class="imgLogo"
                alt="logo"
            /></router-link>
            <h4 class="textLogo d-inline-block my-auto ml-2">fareplay.aero</h4>
          </div>
        </div>
        <ul class="navbar-nav">
          <router-link
            custom
            v-slot="{ navigate, href, isActive }"
            :to="{ name: 'home' }"
          >
            <li :class="{ 'current-menu-item': isActive }">
              <a :href="href" @click="navigate">Home</a>
            </li>
          </router-link>
          <router-link
            custom
            v-slot="{ navigate, href, isActive }"
            :to="{ name: 'articles' }"
          >
            <li :class="{ 'current-menu-item': isActive }">
              <a :href="href" @click="navigate">News</a>
            </li>
          </router-link>
          <router-link
            custom
            v-slot="{ navigate, href, isActive }"
            :to="{ name: 'our-concept' }"
          >
            <li :class="{ 'current-menu-item': isActive }">
              <a :href="href" @click="navigate">Our Concept</a>
            </li>
          </router-link>
          <router-link
            custom
            v-slot="{ navigate, href, isActive }"
            :to="{ name: 'data-mining' }"
          >
            <li :class="{ 'current-menu-item': isActive }">
              <a :href="href" @click="navigate">Data Mining</a>
            </li>
          </router-link>
          <router-link
            custom
            v-slot="{ navigate, href, isActive }"
            :to="{ name: 'data-visualisation' }"
          >
            <li :class="{ 'current-menu-item': isActive }">
              <a :href="href" @click="navigate">Data Visualisation</a>
            </li>
          </router-link>
          <li
            class="menu-item-has-children"
            :class="{
              'current-sub-menu-item':
                $route.name === 'market-analysis' ||
                $route.name === 'it-services',
            }"
          >
            <a href="#">Services</a>
            <ul class="sub-menu">
              <router-link
                custom
                v-slot="{ navigate, href, isActive }"
                :to="{ name: 'market-analysis' }"
              >
                <li :class="{ 'current-menu-item': isActive }">
                  <a :href="href" @click="navigate">Market Analysis</a>
                </li>
              </router-link>
              <router-link
                custom
                v-slot="{ navigate, href, isActive }"
                :to="{ name: 'it-services' }"
              >
                <li :class="{ 'current-menu-item': isActive }">
                  <a :href="href" @click="navigate">IT Services</a>
                </li>
              </router-link>
            </ul>
          </li>
          <router-link
            custom
            v-slot="{ navigate, href, isActive }"
            :to="{ name: 'contact' }"
          >
            <li :class="{ 'current-menu-item': isActive }">
              <a :href="href" @click="navigate">Contact</a>
            </li>
          </router-link>
        </ul>
      </div>
      <div class="nav-right-content">
        <a
          href="https://portal.fareplay.aero"
          class="btn btn-radius btn-blue s-animate-3"
          >Login</a
        >
      </div>
    </div>
  </nav>
  <!-- navbar area end -->
</template>

<script>
export default {
  name: "TopNavBar",
};
</script>