import { createRouter, createWebHistory } from "vue-router"
import Home from "../views/Home.vue"

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/our-concept",
    name: "our-concept",
    component: () =>
      import(/* webpackChunkName: "our_concept" */ "../views/OurConcept.vue"),
  },
  {
    path: "/data-mining",
    name: "data-mining",
    component: () =>
      import(/* webpackChunkName: "data-mining" */ "../views/DataMining.vue"),
  },
  {
    path: "/data-visualisation",
    name: "data-visualisation",
    component: () =>
      import(
        /* webpackChunkName: "data-visualisation" */ "../views/DataVisualisation.vue"
      ),
  },
  {
    path: "/market-analysis",
    name: "market-analysis",
    component: () =>
      import(
        /* webpackChunkName: "market-analysis" */ "../views/MarketAnalysis.vue"
      ),
  },
  {
    path: "/it-services",
    name: "it-services",
    component: () =>
      import(/* webpackChunkName: "it-services" */ "../views/ITServices.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
  },
  {
    path: "/articles",
    name: "articles",
    component: () =>
      import(/* webpackChunkName: "articles" */ "../views/Articles.vue"),
  },
  {
    path: "/article/:id",
    name: "article",
    component: () =>
      import(/* webpackChunkName: "article" */ "../views/Article.vue"),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

export default router
