<template>
  <TopNavBar />
  <!-- header area start -->
  <div
    class="header-area header-bg"
    style="background-image: url(assets/img/bg/banner-bg.png)"
  >
    <div class="container">
      <div class="banner-slider banner-slider-one">
        <div class="banner-slider-item">
          <div class="row">
            <div class="col-xl-5 col-lg-6 col-md-6 offset-xl-1">
              <div class="header-inner-details">
                <div class="header-inner">
                  <h1 class="title s-animate-1">
                    We turn air fare data into <span>insights</span> to propel
                    your business forward
                  </h1>
                  <p class="s-animate-2">
                    In a dynamic aviation industry, knowledge is power. We help
                    you get it.
                  </p>
                  <div class="btn-wrapper desktop-left padding-top-20">
                    <a
                      href="#what-is"
                      class="btn btn-radius btn-blue s-animate-3"
                      >Read More</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 hidden-sm">
              <div class="banner-thumb-wrap">
                <div class="banner-thumb">
                  <img
                    class="header-inner-img"
                    src="assets/img/banner/1_edit.png"
                    alt="banner-img"
                  />
                  <img
                    class="banner-1-img2"
                    src="assets/img/banner/2.png"
                    alt="banner-img"
                  />
                  <img
                    class="banner-1-img3"
                    src="assets/img/banner/3.png"
                    alt="banner-img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- header area End -->
  <div id="what-is" class="mb-8">&nbsp;</div>
  <!-- soft-box area start -->
  <div
    class="sbs-what-riyaqas pd-default-120 mg-top-105"
    style="background-image: url(assets/img/bg/1h1.png)"
  >
    <div class="container">
      <div class="row">
        <div
          class="col-lg-6 wow animated fadeInRight"
          data-wow-duration="1s"
          data-wow-delay="0.3s"
        >
          <!--<img src="assets/img/we-provide/5.png" alt="img" />-->
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/P-xgJIqfhWk"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div class="col-xl-5 col-lg-6 desktop-center-item">
          <div
            class="desktop-center-area wow animated fadeInLeft"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            <div class="section-title style-two">
              <h2 class="title">What is <span>FarePlay?</span></h2>
              <p>
                FarePlay is a fare benchmarking service for the aviation
                industry. It was developed by seasoned airline professionals
                with a background in commercial and IT. We pride ourselves on
                creating relevant tools at affordable prices, and cover most
                airlines on virtually any route worldwide.
              </p>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="riyaqas-check-list">
                  <img
                    class="float-left"
                    src="assets/img/icons/check.svg"
                    alt="check"
                  />
                  <div>Track record in aviation</div>
                </div>
                <div class="riyaqas-check-list">
                  <img
                    class="float-left"
                    src="assets/img/icons/check.svg"
                    alt="check"
                  />
                  <div>Innovative &amp; flexible approach</div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="riyaqas-check-list">
                  <img
                    class="float-left"
                    src="assets/img/icons/check.svg"
                    alt="check"
                  />
                  <div>Efficient setup</div>
                </div>
                <div class="riyaqas-check-list">
                  <img
                    class="float-left"
                    src="assets/img/icons/check.svg"
                    alt="check"
                  />
                  <div>Proprietary knowledge base</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- soft-box area End -->

  <!-- service area start -->
  <div class="service-area mt-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-7 col-lg-10">
          <div class="section-title text-center margin-bottom-90">
            <h2
              class="title wow animated fadeInUp"
              data-wow-duration="0.6s"
              data-wow-delay="0.1s"
            >
              Our <span>services</span> assist you in multiple areas
            </h2>
          </div>
        </div>
      </div>
      <div class="row custom-gutters-16">
        <div class="col-xl-3 col-md-6 col-sm-6">
          <div
            class="single-service wow animated fadeInUp"
            data-wow-duration="0.4s"
            data-wow-delay="0.1s"
          >
            <div class="text-center">
              <img src="assets/img/service/project.svg" alt="service" />
            </div>
            <h6 class="pt-2 mb-1">
              <router-link :to="{ name: 'data-mining' }"
                >Data Mining</router-link
              >
            </h6>
            <p>
              Our next-generation architecture enables quick retrieval of large
              volumes of air and rail price data.
            </p>
            <div class="read-more">
              <router-link :to="{ name: 'data-mining' }"
                ><img src="assets/img/service/arrow.png" alt="arrow"
              /></router-link>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6 col-sm-6">
          <div
            class="single-service wow animated fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.4s"
          >
            <div class="text-center">
              <img src="assets/img/service/analytics.svg" alt="service" />
            </div>
            <h6 class="pt-2 mb-1">
              <router-link :to="{ name: 'data-visualisation' }"
                >Data visualisation</router-link
              >
            </h6>
            <p>
              Our handcrafted dashboards are based on typical use cases, yet
              allow 'slicing &amp; dicing' for your particular situation.
            </p>
            <div class="read-more">
              <router-link :to="{ name: 'data-visualisation' }"
                ><img src="assets/img/service/arrow.png" alt="arrow"
              /></router-link>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6 col-sm-6">
          <div
            class="single-service wow animated fadeInUp"
            data-wow-duration="0.6s"
            data-wow-delay="0.2s"
          >
            <div class="text-center">
              <img src="assets/img/service/hiring.svg" alt="service" />
            </div>
            <h6 class="pt-2 mb-1">
              <router-link :to="{ name: 'market-analysis' }"
                >Market Analysis</router-link
              >
            </h6>
            <p>
              Our expertise helps you take fare data to the next level by
              solving higher-order business questions.
            </p>
            <div class="read-more">
              <router-link :to="{ name: 'market-analysis' }"
                ><img src="assets/img/service/arrow.png" alt="arrow"
              /></router-link>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6 col-sm-6">
          <div
            class="single-service wow animated fadeInUp"
            data-wow-duration="0.8s"
            data-wow-delay="0.3s"
          >
            <div class="text-center">
              <img src="assets/img/service/checklist.svg" alt="service" />
            </div>
            <h6 class="pt-2 mb-1">
              <router-link :to="{ name: 'it-services' }"
                >IT Services</router-link
              >
            </h6>
            <p>
              Our team of professionals can assist you with the setup and
              integration of a robust architecture.
            </p>
            <div class="read-more">
              <router-link :to="{ name: 'it-services' }"
                ><img src="assets/img/service/arrow.png" alt="arrow"
              /></router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- service area End -->

  <div class="sbs-talented-people mg-top-120">
    <div class="container">
      <div class="row">
        <div class="col-xl-5 col-lg-6 offset-xl-1 desktop-center-item">
          <div class="desktop-center-area">
            <div class="section-title style-three">
              <h2 class="title">Our <span>Team</span></h2>
              <p>
                We combine high-powered skills from a variety of backgrounds,
                with a focus on Commercial and IT. Our expertise ranges from
                analytical to senior management roles in various geographies,
                which makes it easy for us to understand Clients' needs.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="world-map-wrap">
            <img src="assets/img/others/map_transparant.png" alt="map" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- soft-box area start -->
  <div
    class="
      leftside-image-area
      service-area
      sbs-business-tool
      pd-bottom-120
      mg-top-120
    "
    style="background-image: url(assets/img/bg/1h2.png)"
  >
    <div class="container">
      <div class="row">
        <div
          class="col-lg-6 col-xl-7 wow animated fadeInRight"
          data-wow-duration="1s"
          data-wow-delay="0.3s"
        >
          <img src="assets/img/business-tool/1.png" alt="img" />
        </div>
        <div class="col-xl-5 col-lg-6 desktop-center-item">
          <div
            class="desktop-center-area wow animated fadeInLeft"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            <div class="section-title style-two">
              <h2 class="title">We listen to our <span>partners</span></h2>
              <p>
                We have wide experience catering to airlines, airports, 'Big
                Data' users and advisory firms.
              </p>
            </div>
            <div class="row">
              <div class="col-lg-9">
                <div class="riyaqas-check-list media">
                  <img
                    class="media-left"
                    src="assets/img/business-tool/pencil.svg"
                    alt="check"
                  />
                  <span
                    >Our proprietary fare search engine and dashboards allow for
                    an efficient and scalable setup.</span
                  >
                </div>
                <div class="riyaqas-check-list media">
                  <img
                    class="media-left"
                    src="assets/img/business-tool/search.svg"
                    alt="check"
                  />
                  <span
                    >Our frontier mindset continuously explores new areas to
                    better serve our partners.</span
                  >
                </div>
                <div class="riyaqas-check-list media">
                  <img
                    class="media-left"
                    src="assets/img/business-tool/settings.svg"
                    alt="check"
                  />
                  <span
                    >Our tailored mix of raw fare data, unique dashboards and
                    consulting services enables us to find the right solution
                    for you.</span
                  >
                </div>
              </div>
            </div>
            <a
              class="btn btn-radius btn-blue s-animate-3 mt-3"
              href="mailto:info@fareplay.aero"
              ><i class="fa fa-paper-plane"></i> Contact us</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- soft-box area End -->

  <!-- testimonial area Start -->
  <div
    class="testimonial-section sbs-testimonial-section pd-top-105 pd-bottom-120"
    style="background-image: url(assets/img/bg/1h3.png)"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-5 col-lg-6 pd-xl-5 order-lg-12 align-self-center">
          <div class="section-title style-two mb-0">
            <h2 class="title">What our <span>Clients</span> say?</h2>
            <p>
              We pride ourselves on developing stimulating relationships with
              our partners.
            </p>
          </div>
        </div>
        <div class="col-lg-6 order-lg-1">
          <div class="card card-body">
            <div class="media">
              <img
                class="media-left"
                :src="require('@/assets/img/clients/mst_round_logo_small.png')"
                alt="client"
              />
              <div class="media-body">
                <h6>Fons Latour</h6>
                <p class="designation">
                  Chief Operations Officer Maastricht Aachen Airport
                </p>
              </div>
            </div>
            <p>
              “<a class="color-main" href="https://fareplay.aero"
                >fareplay.aero</a
              >
              has a very fresh and direct approach to providing fare data for
              airports and airlines. The hands-on mentality and demonstrable
              aviation knowledge provide a solid basis for this service.”
            </p>
          </div>
          <!-- <div class="sbs-testimonial-slider">
            <div class="choose_slider">
              <div class="choose_slider_items">
                <ul id="testimonial-slider">
                  <li class="current_item">
                    <div class="media">
                      <img
                        class="media-left"
                        :src="require('@/assets/img/mst_logo.png')"
                        alt="client"
                      />
                      <div class="media-body">
                        <h6>Wilson Mojika</h6>
                        <p class="designation">Founder at todo</p>
                        <span>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                        </span>
                      </div>
                    </div>
                    <p>
                      Our support team will get assistance from AI-powered
                      suggestions, making it quicker than ever to handle support
                      requests.
                    </p>
                  </li>
                  <li>
                    <div class="media">
                      <img
                        class="media-left"
                        src="assets/img/client/5.png"
                        alt="client"
                      />
                      <div class="media-body">
                        <h6>Ema Wilson</h6>
                        <p class="designation">CEO at craft</p>
                        <span>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                        </span>
                      </div>
                    </div>
                    <p>
                      Our support team will get assistance from AI-powered
                      suggestions, making it quicker than ever to handle support
                      requests.
                    </p>
                  </li>
                  <li>
                    <div class="media">
                      <img
                        class="media-left"
                        src="assets/img/client/6.png"
                        alt="client"
                      />
                      <div class="media-body">
                        <h6>John Mojika</h6>
                        <p class="designation">CTO of Uxa</p>
                        <span>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                        </span>
                      </div>
                    </div>
                    <p>
                      Our support team will get assistance from AI-powered
                      suggestions, making it quicker than ever to handle support
                      requests.
                    </p>
                  </li>
                  <li>
                    <div class="media">
                      <img
                        class="media-left"
                        src="assets/img/client/4.png"
                        alt="client"
                      />
                      <div class="media-body">
                        <h6>Refule Mia</h6>
                        <p class="designation">CEO at craft</p>
                        <span>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                        </span>
                      </div>
                    </div>
                    <p>
                      Our support team will get assistance from AI-powered
                      suggestions, making it quicker than ever to handle support
                      requests.
                    </p>
                  </li>
                  <li>
                    <div class="media">
                      <img
                        class="media-left"
                        src="assets/img/client/5.png"
                        alt="client"
                      />
                      <div class="media-body">
                        <h6>Madeniko Mojika</h6>
                        <p class="designation">Branding Idendity</p>
                        <span>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                        </span>
                      </div>
                    </div>
                    <p>
                      Our support team will get assistance from AI-powered
                      suggestions, making it quicker than ever to handle support
                      requests.
                    </p>
                  </li>
                  <li>
                    <div class="media">
                      <img
                        class="media-left"
                        src="assets/img/client/6.png"
                        alt="client"
                      />
                      <div class="media-body">
                        <h6>Madeniko Mojika</h6>
                        <p class="designation">CTO of Uxa</p>
                        <span>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                        </span>
                      </div>
                    </div>
                    <p>
                      Our support team will get assistance from AI-powered
                      suggestions, making it quicker than ever to handle support
                      requests.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div class="sbs-arrowleft">
              <a id="btn_next" href="#"
                ><i class="fa fa-long-arrow-left"></i
              ></a>
            </div>
            <div class="sbs-arrowright">
              <a id="btn_prev" href="#"
                ><i class="fa fa-long-arrow-right"></i
              ></a>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <!-- testimonial area End -->

  <LetsTalk />
  <Footer />

  <!-- back to top area start -->
  <div class="back-to-top">
    <span class="back-top"><i class="fa fa-angle-up"></i></span>
  </div>
  <!-- back to top area end -->
</template>

<script>
import TopNavBar from "@/components/TopNavBar.vue";
import LetsTalk from "@/components/LetsTalk.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Home",
  components: {
    TopNavBar,
    LetsTalk,
    Footer,
  },
};
</script>

<style scoped>
.mb-8 {
  margin-bottom: 8rem !important;
}
</style>
