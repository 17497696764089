<template>
  <div id="contact" class="newsletter-area mg-top-110">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-7 col-lg-10 text-center">
          <div class="section-title">
            <h2 class="title">Let's <span>Talk</span></h2>
            <p>We look forward to discussing how we can help you.</p>
            <p class="mt-0">
              Feel free to contact us at
              <a class="color-main" href="mailto:info@fareplay.aero"
                >info@fareplay.aero</a
              >.
            </p>
          </div>
          <!--<div class="newsletter-subcribe">
            <form id="news-subcribeform" class="subcribe-form">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Your E-mail..."
                  name="mail"
                  required=""
                  disabled
                />
                <button type="submit" class="btn-green subcribe-submit">
                  submit
                </button>
              </div>
            </form>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.color-main {
  color: var(--main-color-one);
}
</style>