import { db } from '../../../src/firebase'

export default {
  namespaced: true,
  state() {
    return {
      articles: [],
      isLoading: true,
    }
  },
  getters: {
    isLoading: (state) => {
      return state.isLoading
    },
    articles: (state) => {
      return state.articles
    },
  },
  mutations: {
    setArticles(state, articles) {
      state.articles = articles
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading
    },
  },
  actions: {
    getArticles({ commit }) {
      return new Promise((resolve, reject) => {
        commit('setIsLoading', true)
        try {
          db.collection('articles')
            .where('publish_on', '<=', new Date())
            .orderBy('publish_on', 'desc')
            .onSnapshot(
              (querySnapshot) => {
                let items = []
                querySnapshot.forEach((doc) => {
                  let itemData = doc.data()
                  itemData.id = doc.id
                  items.push(itemData)
                })
                commit('setArticles', items)
                resolve(true)
              },
              (error) => {
                console.error(error)
                reject(error)
              }
            )
        } catch (error) {
          console.error(error)
        } finally {
          commit('setIsLoading', false)
        }
      })
    },
    // getFiles({ state, commit, rootGetters }) {
    //     state.files = [];
    //     const userProfile = rootGetters["auth/userProfile"];
    //     const company = userProfile.profile.company;
    //     const storageRef = storage.refFromURL(
    //         "gs://fareplay_customers/" + company
    //     );
    //     storageRef
    //         .listAll()
    //         .then((result) => {
    //             commit("setLastUpdateNow");
    //             for (let i = 0; i < result.items.length; i++) {
    //                 const fileRef = result.items[i];
    //                 fileRef
    //                     .getMetadata()
    //                     .then((metadata) => {
    //                         let file = { metadata: metadata, ref: fileRef };
    //                         commit("addFile", file);
    //                     })
    //                     .catch((error) => {
    //                         console.error(error);
    //                     });
    //             }
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         });
    // }
  },
  modules: {},
}
